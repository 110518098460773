import { ComponentRef, FlowEditorSDK } from '@wix/yoshi-flow-editor';

import { getComponentRef } from './getComponenetRef';

export const getPresetId = async (
  editorSDK: FlowEditorSDK,
  appDefinitionId: string,
): Promise<{ presetId?: string; componentRef?: ComponentRef }> => {
  const componentRef = await getComponentRef(editorSDK, appDefinitionId);
  const result = {
    presetId: undefined,
    componentRef,
  };

  if (!componentRef) {
    return result;
  }

  const data = await editorSDK.document.tpa.data.getAll('', {
    compRef: componentRef,
  });

  if (!data) {
    return result;
  }

  return {
    presetId: data.COMPONENT?.presetId as string | undefined,
    componentRef,
  };
};
