import { FlowEditorSDK } from '@wix/yoshi-flow-editor';

import { APP_DEFINITION_ID, DEFAULT_WIDGET_POSITION } from '@/constants';

import { getComponentRef } from './getComponenetRef';

export const getWidgetPosition = async ({
  editorSDK,
  appDefId,
}: {
  editorSDK: FlowEditorSDK;
  appDefId?: string;
}): Promise<'left' | 'right'> => {
  const componentRef = await getComponentRef(
    editorSDK,
    appDefId ?? APP_DEFINITION_ID,
  );
  if (!componentRef) {
    return DEFAULT_WIDGET_POSITION;
  }

  const properties = await editorSDK.components.properties.get('token', {
    componentRef,
  });

  return properties?.placement?.includes('LEFT') ? 'left' : 'right';
};

export const setWidgetPosition = async ({
  editorSDK,
  position,
}: {
  editorSDK: FlowEditorSDK;
  position: 'left' | 'right';
}) => {
  const componentRef = await getComponentRef(editorSDK, APP_DEFINITION_ID);
  if (!componentRef) {
    return;
  }

  await editorSDK.components.properties.update('token', {
    componentRef,
    props: {
      placement: `BOTTOM_${position.toUpperCase()}`,
    },
  });
};

export const updateLayout = async ({
  editorSDK,
  position,
}: {
  editorSDK: FlowEditorSDK;
  position: 'left' | 'right';
}) => {
  const componentRef = await getComponentRef(editorSDK, APP_DEFINITION_ID);
  if (!componentRef) {
    return;
  }

  await Promise.all([
    editorSDK.components.properties.update('token', {
      componentRef,
      props: {
        placement: `BOTTOM_${position.toUpperCase()}`,
      },
    }),
    editorSDK.document.tpa.data.set('token', {
      compRef: componentRef,
      key: 'layout',
      value: position,
      scope: 'COMPONENT',
    }),
  ]);
};
